
import { defineComponent, onBeforeMount, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import helper from "@/utils/helper";
interface Idata {
  name: string;
  english: string;
  city: string;
  desc: string;
}
export default defineComponent({
  name: "coreTeam",
  setup() {
    const router = useRouter();
    const dataSource = ref<Idata>({
      name: "",
      english: "",
      city: "",
      desc: "",
    });
    const mobile = ref(false);
    onBeforeMount(() => {
      if (helper.is_mobile()) {
        mobile.value = true;
      }
      const url =
        process.env.NODE_ENV == "production"
          ? "/data/team.json"
          : "/data/team.json";
      axios.get(url).then((res) => {
        if (res.status == 200) {
          dataSource.value = res.data;
        }
      });
    });

    const detail = (id: string): void => {
      // router.push({path:'/teamDetails',query:{
      //     id
      // }})
      location.href = process.env.NODE_ENV == "production"?`/teamDetails?id=${id}`:`/teamDetails?id=${id}`;
    };

    return {
      dataSource,
      detail,
      mobile,
    };
  },
});
